import React, { Component } from "react"
import CoolTabs from "react-cool-tabs"
import { Container } from "react-bootstrap"
import Board from "./Board"
import Team from "./Team"
import { board, team } from "../../../constants"
import "./index.scss"

class Members extends Component {
  constructor(props) {
    super(props)
    this.state = {
      matches:
        typeof window !== "undefined" &&
        window.matchMedia("(max-width: 950px)").matches,
      activeTab: "Board",
    }
  }

  componentDidMount() {
    const handler = e => this.setState({ matches: e.matches })
    window.matchMedia("(max-width: 950px)").addListener(handler)
  }

  async updateActiveTab(e) {
    await this.setState({
      ...this.state,
      activeTab: e.currentTarget.childNodes[0].data,
    })
  }

  render() {
    const { matches, activeTab } = this.state

    return (
      <div className="members__container">
        <Container onClick={e => this.updateActiveTab(e)}>
          <CoolTabs
            tabKey="1"
            className={
              activeTab === "Board" ?
                "coolTabs__containerBoard" :
                "coolTabs__containerTeam"
            }
            style={{
              background: `var(--mainWhite)`,
            }}
            tabsHeaderStyle={{
              justifyContent: "center",
              background: `var(--mainMediumAquamarineRGB) 0% 0% no-repeat padding-box`,
              margin: "2em auto",
              border: `4px solid var(--mainMediumAquamarineRGB)`,
              width: `${matches ? "100%" : "40%"}`,
              borderRadius: "5em",
              height: "46px",
            }}
            activeTabStyle={{
              backgroundColor: `var(--mainMediumAquamarineTwo)`,
              color: `var(--mainBlackRussian)`,
              borderRadius: "5em",
              transition: "all 0.3s ease-out",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
            }}
            unActiveTabStyle={{
              color: `var(--mainWhite)`,
              borderRadius: "5em",
              transition: "all 0.3s ease-out",
              fontFamily: "Poppins, sans-serif",
              background: `rgba(101, 235, 170, 0) 0% 0% no-repeat padding-box`,
              fontWeight: 600,
            }}
            activeLeftTabBorderBottomStyle={{
              background: "inherit",
              height: 0,
            }}
            activeRightTabBorderBottomStyle={{
              background: "inherit",
              height: 0,
            }}
            tabsBorderBottomStyle={{
              background: "none",
              height: 0,
            }}
            leftContentStyle={{
              background: "var(--mainWhite)",
            }}
            rightContentStyle={{
              background: "var(--mainWhite)",
            }}
            leftTabTitle="Board"
            rightTabTitle="Team"
            leftContent={<Board board={board} />}
            rightContent={<Team team={team} />}
            contentTransitionStyle="transform 0.6s ease-in"
            borderTransitionStyle="all 0.6s ease-in"
          />
        </Container>
      </div>
    )
  }
}

export default Members
