import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./index.scss"
import Background from  '../../../assets/images/about-us/background.svg'

const Values = () => (
  <div className="values">
    <div className="values__background">
      <img src={Background}/>
    </div>
    <Container fluid>
      <h1>Values</h1>
      <Row>
        <Col>
          <div className="values__content">
            <h4>People</h4>
            <p>People The building blocks of families, communities, and nations are people. <br /><br /> We seek to connect with our stakeholders as people and create an environment where we share what motivates us to create lasting bonds.</p>
          </div>
        </Col>
        
        <Col>
          <div className="values__content">
            <h4>Innovation</h4>
            <p>Innovation We are never satisfied with the status quo and relentlessly seek to improve. Innovation is a natural output when we understand people’s motivations individually and work together as teams towards a shared vision.</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="values__content">
            <h4>Teamwork</h4>
            <p>Teamwork When pursuing our mission and vision, our methodology is founded on collaboration and working as a team to solve the problems we all face.</p>
          </div>
        </Col>

        <Col>
          <div className="values__content">
            <h4>Integrity</h4>
            <p>Integrity We believe in being authentic. We believe in standing for what is right. What we say is what we do.</p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Values
