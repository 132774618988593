import React from "react"
import Banner from "./Banner"
import MissionVision from "./MissionVision"
import Values from "./Values"
import MeetTheTeam from "../MeetTheTeam"

function AboutUs() {
  return (
    <div style={{overflow: 'hidden'}}>
      <Banner />
      <MissionVision />
      <Values />
      <MeetTheTeam />
    </div>
  )
}

export default AboutUs
