import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./index.scss"
import DeskPerson from "../../../assets/images/about-us/desk-person.svg"

const MissionVision = () => (
  <div className="mission__vision">
    <Container fluid>
      <Row>
        <Col>
          <div className="mission__vision__image">
            <img
              src={DeskPerson}
              alt="person at desk"
              className="mission__vision__desk"
            />
          </div>
        </Col>
        <Col style={{ zIndex: 1 }}>
          <div className="mission__vision__content">
            <h1>Mission</h1>
            <p>
              We enhance the quality of life of our customers through simple,
              convenient, and appropriate financial services.
            </p>
          </div>

          <div className="mission__vision__content">
            <h1>Vision</h1>
            <p>
              We are creating an ecosystem where people and organisations can
              access financial solutions that help them meet their objectives.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default MissionVision
