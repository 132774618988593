import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutUs from "../components/AboutUs"

const AboutUsPage = () => (
  <Layout>
    <Seo title="About Us" />
    <AboutUs />
  </Layout>
)

export default AboutUsPage
