import React from "react"
import { Container } from "react-bootstrap"
import { Title } from "../ui"
import Members from "./Members"
import "./index.scss"
import QuoteMark from "../../assets/images/meet-the-team/quote-mark.svg"

const MeetTheTeam = () => {
  return (
    <div className="meetTheTeam__container">
      <Container>
        <Title header="Meet the team" color="BlackRussian" />
        <div className="meetTheTeam__section">
          <p>
            Fin is a team of fintech professionals and entrepreneurs from
            around the world. We’re passionate about building products that help
            to make financial tools easily accessible for people throughout the
            African continent.
          </p>
          <p>
            By leveraging our risk, credit, and technology expertise, we aim to
            be at the forefront of the fintech sector and pride ourselves on our
            supervised machine learning and AI capabilities. These tools give us
            the ability to make highly informed predictions on credit
            applications in seconds; based on both financial and psychometric
            data.
          </p>
          <p>
            Our product offering focuses on creating value for employers,
            employees and consumers while closing the credit gap that persists
            on the continent.
          </p>
          <p>
            We’re excited about building the future of African financial
            technology.
          </p>
        </div>
        <Members />
      </Container>
      <div className="meetTheTeam__bottom">
        <img src={QuoteMark} alt="our team" />
        <h5>
          Our people are the foundation of our success and the key to our
          sustainability.
        </h5>
      </div>
    </div>
  )
}

export default MeetTheTeam
