import React, { Component } from "react"
import MemberCard from "./MemberCard"
import "./Board.scss"

class Board extends Component {
  render() {
    const { board } = this.props

    return (
      <div className="board__container">
        {board.map(boardMembers => {
          const { name, linkedInLink, image, id, title } = boardMembers

          return (
            <div key={id} className="">
              <MemberCard
                {...{
                  name,
                  linkedInLink,
                  image,
                  id,
                  title,
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

export default Board
