import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Button from "../../ui/Button"
import "./index.scss"
import AboutUsBanner from "../../../assets/images/about-us/about-us-banner.svg"

const Banner = () => (
  <div className="about__us__banner__container">
    <Container fluid>
      <Row>
        <Col style={{ display: "flex" }}>
          <div className="banner__caption">
            <h1>About us</h1>
            <p>
              We are Fin (formerly Finclusion Group) - providing simply smarter
              finance for everybody. Our products include credit, insurance,
              financial wellness and more.
            </p>
            {/* <Link to="/products">
              <Button
                title="Learn more"
                customClass="banner__btn"
                type="button"
                size="md"
              />
            </Link> */}
          </div>
        </Col>
        <Col style={{ zIndex: 1 }}>
          <div className="banner__image">
            <img
              src={AboutUsBanner}
              alt="map of africa"
              className="banner__imageMap"
            />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Banner
